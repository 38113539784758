<template>
	<div style="overflow:hidden;background-color: #f0f0f0;min-width: 1500px;">
    <RoomNav name="RoomNav"></RoomNav>
		<div style="width: 1500px;margin:0 auto;overflow: hidden;margin-top:108px;">
			<div class="page_content"
				 style="width:894px;height: auto;float: left;margin: 0 10px 0 294px;min-height: 1000px;">
				<div style="overflow:hidden;">
					<!-- 背景图 -->
					<div :style="{'background-image': `url(${room.cover})`}" style="height:250px; width:100%; position: relative; background-repeat:no-repeat; background-size:100%; background-position:center center;">
                    <div class="tag-type" :style="{borderLeftColor: typeList.find(e => e.value == room.roomType).color, borderTopColor: typeList.find(e => e.value == room.roomType).color}"></div>
                    <div class="tag-type-bottom" :style="{borderRightColor: typeList.find(e => e.value == room.roomType).color, borderBottomColor: typeList.find(e => e.value == room.roomType).color}"></div>
						<div style="position: relative; width:100%;height:300px;padding-top:10px;">
<!--							<div class="hand op sc" style="position:absolute;top: 10px;right: 20px;color:#FFFFFF; font-weight: 600" @click="share"><i class="el-icon-share" style="margin-right: 4px"></i>分享</div>-->
							<div style="height:auto;width: 400px; position: relative; margin:0 auto; color:#FFF;">
								<div style="font-size:25px; text-align:center; font-weight:bolder; margin-top:40px; height:40px;line-height:40px;overflow:hidden;">
									<span style="margin-right:5px;">{{room.name}}</span>
								</div>

								<div style="height:20px;line-height:20px; text-align:center;color:#FFFFFF; font-size:14px;margin-top:10px;font-weight: 600;">
									<span>{{room.click_number}}浏览</span>
									<span style="margin: 0 15px;">·</span>
									<span>{{room_user_list.followNum}} 粉丝</span>
									<span style="margin: 0 15px;">·</span>
									<span>{{room.content_number}}讨论</span>
								</div>
							</div>

							<div style="margin: 20px auto; overflow: hidden;width: 284px; text-align: center" v-if="room.myLv == 3">
								<el-button type="primary" class="op sc" style="background:#FF9800;border-color:#FF9800;width:155px; margin-right: 10px">Room内部成员</el-button>
								<el-dropdown @command="room_set">
								  <el-button type="primary" style="background:#5a6fe6;border-color:#5a6fe6;width:110px;">创建事件<i class="el-icon-caret-bottom" style="margin-left: 4px"></i></el-button>
								  <el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="v"><img src="./imgs/event7.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">视频会议</el-dropdown-item>
									<el-dropdown-item command="y"><img src="./imgs/event7.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">视频直播</el-dropdown-item>
									<el-dropdown-item command="a"><img src="./imgs/event1.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">活动报名</el-dropdown-item>
									<!--										<el-dropdown-item command="b"><img src="./imgs/event2.png"-->
									<!--																		   style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">视频直播-->
									<!--										</el-dropdown-item>-->
									<el-dropdown-item command="f"><img src="./imgs/event4.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">打卡事件</el-dropdown-item>
									<el-dropdown-item command="e"><img src="./imgs/event5.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">日历事件</el-dropdown-item>
									<el-dropdown-item command="j"><img src="./imgs/event6.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">付费文档</el-dropdown-item>
									<!--                                        <el-dropdown-item command="k"><img src="./imgs/event7.png"-->
									<!--                                                                           style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">支付事件-->
									<!--                                        </el-dropdown-item>-->
									<el-dropdown-item command="c"><img src="./imgs/event3.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">审批流</el-dropdown-item>
                                      <el-dropdown-item command="r"><img src="@/assets/imgs/room/share.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">分享room</el-dropdown-item>
								  </el-dropdown-menu>
								</el-dropdown>
							</div>

							<div style="margin: 20px auto; overflow: hidden;width: 378px; text-align: center" v-if="room.myLv == 0">
								<el-button type="primary" class="op sc" v-if="room.password === 1" style="background:#5a6fe6;border-color:#5a6fe6;width:80px;" @click="toShowCommand">口令</el-button>
								<el-button type="primary" class="op sc" style="background:#5a6fe6;border-color:#5a6fe6;width:100px;" @click="collectionRoom(room.id)"><i class="el-icon-plus"></i> 关注</el-button>
							</div>

							<div style="margin: 20px auto; overflow: hidden;width: 378px; text-align: center" v-if="room.myLv == 5">
								<el-button type="primary" class="op sc" v-if="room.password === 1" style="background:#5a6fe6;border-color:#5a6fe6;width:80px;" @click="toShowCommand">口令</el-button>
								<el-button type="primary" class="op sc" style="background:#5a6fe6;border-color:#5a6fe6;width:155px;" @click="room_user_add" v-if="room.auth==0">申请成为内部成员</el-button>
								<el-button type="primary" class="op sc" @click="collectionRoom(room.id)" style="background:#FF9800;border-color:#FF9800;width:100px;color: #FFFFFF">取消关注</el-button>
							</div>

							<div style="margin: 20px auto; overflow: hidden;width: 268px; text-align: center" v-if="room.myLv == 4">
								<el-button type="primary" class="op sc" style="background:#5a6fe6;border-color:#5a6fe6;width:155px;">正在申请</el-button>
							</div>

							<div style="margin: 20px auto; width: 66%; overflow: hidden; text-align: center" v-if="room.myLv == 2 || room.myLv == 1">
								<el-button v-if="room.myLv == 1" type="primary" @click="editRoomInfo(room.id)" style="background:#5a6fe6;border-color:#5a6fe6;width:140px;">编辑Room信息</el-button>
								<el-button type="primary" style="background:#5a6fe6;border-color:#5a6fe6;width:140px;margin-left: 10px" @click="utils.a('/room/member/' + room.id)">管理Room成员</el-button>
								<el-button v-if="room.myLv == 1" type="primary" style="background:#5a6fe6;border-color:#5a6fe6;width:140px;margin-left: 10px" @click="utils.a('/room/menu/' + room.id)">管理Room菜单</el-button>
								<el-dropdown @command="room_set">
									<el-button type="primary" style="background:#5a6fe6;border-color:#5a6fe6;width:110px;margin-left: 10px">创建事件<i class="el-icon-caret-bottom" style="margin-left: 4px"></i></el-button>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item command="v"><img src="./imgs/event7.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">视频会议</el-dropdown-item>
										<el-dropdown-item v-if="room.auth !== 1" command="y"><img src="./imgs/event7.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">视频直播</el-dropdown-item>
										<el-dropdown-item v-if="room.auth !== 1" command="a"><img src="./imgs/event1.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">活动报名</el-dropdown-item>
<!--										<el-dropdown-item command="b"><img src="./imgs/event2.png"-->
<!--																		   style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">视频直播-->
<!--										</el-dropdown-item>-->
										<el-dropdown-item v-if="room.auth !== 1" command="f"><img src="./imgs/event4.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">打卡事件</el-dropdown-item>
										<el-dropdown-item command="e"><img src="./imgs/event5.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">日历事件</el-dropdown-item>
										<el-dropdown-item v-if="room.auth !== 1" command="j"><img src="./imgs/event6.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">付费文档</el-dropdown-item>
										<!--                                        <el-dropdown-item command="k"><img src="./imgs/event7.png"-->
										<!--                                                                           style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">支付事件-->
										<!--                                        </el-dropdown-item>-->
										<el-dropdown-item command="c"><img src="./imgs/event3.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">审批流</el-dropdown-item>
                                        <el-dropdown-item command="r"><img src="@/assets/imgs/room/share.png" style="width: 16px; height: 16px; vertical-align: middle; margin:0 8px 0 2px">分享room</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
							<div style="color: #FFFFFF; position: absolute; bottom: 70px; left: 10px" v-if="room.createTime">
                                <span v-if="room.auth === 1" style="border: 2px solid;display: inline-block;margin-right: 4px; width: 20px; text-align: center; vertical-align: middle; height: 20px; border-radius: 50%;color: #FFFFFF; background-color: #1a1a1a" :style="{borderColor: typeList.find(e => e.value == room.roomType).color}">私</span>
								本ROOM创建于{{room.createTime}}
							</div>
						</div>
					</div>

					<!-- 视频直播 -->
					<div style="width: 100%;height: 550px;margin-top: 20px;"  v-if="living">
						<live :role="role" :live-id="joinLiveId" :room-id="room.id" :onCreateLive="onCreateLive" :on-close="onCloseLive"></live>
					</div>


					<div style="display: flex; justify-content: space-between; align-items: center;background-color: #ffffff;height: 52px;margin:6px 0;font-weight: 400;font-size: 16px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);" v-if="is_login">
						<div style="width: 300px;display: flex;justify-content: flex-start;align-items: center;height: 100%; padding: 0 10px">
							<div v-if="room.roomType !== 3 && room.auth !== 1 && (room.myLv === 1 || room.myLv === 2 || room.myLv === 3)" style="cursor: pointer;display: flex; width: 130px;justify-content: center;align-items: center;border: 2px #FF5E5E solid; padding: 4px 0; margin-right: 10px">
								<el-dropdown trigger="click" @command="answer">
									<div style="color: #FF5E5E; font-size: 16px;">
										<img src="./imgs/paper.png" style="width: 16px;height: 16px;margin-right :5px; line-height: 16px; vertical-align: middle">
										<span style="vertical-align: middle">红包问答</span>
										<i class="el-icon-caret-bottom" style="margin-left: 6px; vertical-align: middle"></i>
									</div>

									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item command="1"><img src="./imgs/problem.png" class="drop-img"><span style="vertical-align: middle">红包问题</span></el-dropdown-item>
										<el-dropdown-item command="2"><img src="./imgs/reward.png" class="drop-img"><span style="vertical-align: middle">悬赏资料</span></el-dropdown-item>
										<el-dropdown-item command="3"><img src="./imgs/discuss.png" class="drop-img"><span style="vertical-align: middle">悬赏讨论</span></el-dropdown-item>
										<el-dropdown-item command="4"><img src="./imgs/bid.png" class="drop-img"><span style="vertical-align: middle">比选竞标</span></el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>

							<div style="cursor: pointer;display: flex; width: 130px;justify-content: center;align-items: center; border: 2px #FFA500 solid; padding: 4px 0" v-if="room.auth !== 1 && (room.myLv === 1 || room.myLv === 2 || room.myLv === 3)">
								<el-dropdown trigger="click" @command="business">
									<div style="color: #FFA500; font-size: 16px;">
										<img src="./imgs/template.png" style="width: 16px;height: 16px;margin-right :5px; line-height: 16px; vertical-align: middle">
										<span style="vertical-align: middle">模板信息</span>
										<i class="el-icon-caret-bottom" style="margin-left: 6px; vertical-align: middle"></i>
									</div>

									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item command="proJect">
                                            <img src="./imgs/pag.png" class="drop-img">
                                            <span style="vertical-align: middle">招标信息</span>
                                        </el-dropdown-item>
                                        <el-dropdown-item command="purChase">
                                            <img src="./imgs/purchase.png" class="drop-img">
                                            <span style="vertical-align: middle">采购信息</span>
                                        </el-dropdown-item>
                                        <el-dropdown-item command="recRuit">
                                            <img src="./imgs/recruit.png" class="drop-img">
                                            <span style="vertical-align: middle">招募信息</span>
                                        </el-dropdown-item>
										<el-dropdown-item command="buSiness">
                                            <img src="./imgs/commerce.png" class="drop-img">
                                            <span style="vertical-align: middle">商务信息</span>
                                        </el-dropdown-item>
                                        <el-dropdown-item command="invesTment">
                                            <img src="./imgs/attract.png" class="drop-img">
                                            <span style="vertical-align: middle">招商信息</span>
                                        </el-dropdown-item>
										<el-dropdown-item command="deMand">
                                            <img src="./imgs/demand.png" class="drop-img">
                                            <span style="vertical-align: middle">需求信息</span>
                                        </el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</div>

						<div>
							<el-radio v-if="room.roomType !== 3 && room.auth !== 1" v-model="showOrder" label="red" style="margin-right: 30px" @click.native.prevent="checkRadio('red')">红包问答</el-radio>
							<el-radio v-if="room.auth !== 1" v-model="showOrder" label="template" style="margin-right: 30px" @click.native.prevent="checkRadio('template')">模板信息</el-radio>
							<span>
                                <el-radio v-model="showOrder" label="merchants" style="margin-right: 30px" @click.native.prevent="checkRadio('merchants')">服务</el-radio>
                            </span>
							<span>
                                <el-radio v-model="showOrder" label="product" style="margin-right: 30px" @click.native.prevent="checkRadio('product')">产品</el-radio>
                            </span>
							<el-radio v-model="showOrder" label="expert" style="margin-right: 30px" @click.native.prevent="checkRadio('expert')">专家</el-radio>
						</div>
					</div>

					<div style="margin-bottom: 10px" v-if="showOrder === 'merchants' || showOrder === 'expert' || showOrder === 'product'">
						<InfoOrder :type="showOrder"></InfoOrder>
					</div>

					<div style="margin-bottom: 10px" v-if="showOrder === 'red'">
						<Question ref="childQuestion"></Question>
					</div>

					<div style="margin-bottom: 10px" v-if="showOrder === 'template'">
						<Information ref="information"></Information>
					</div>

					<div v-if="showOrder == 'content' && is_login">
						<Input :roomId="id" v-if="room.auth == 0 && (room.fansInput === 0 || (room.fansInput === 1 && room.myLv !== 5)) && room.myLv !== 0"></Input>

                        <div style="margin-top: 10px">
                          <el-input placeholder="请输入搜索内容" v-model="content" class="input-with-select">
                            <el-select v-model="selectType" slot="prepend" style="width: 86px" placeholder="请选择">
                              <el-option label="全部" :value="0"></el-option>
                              <el-option label="自己" :value="1"></el-option>
                            </el-select>
                            <el-button class="hand op sc" style="width: 80px; text-align: center" @click="roomSearch" slot="append">
                              <img style="width: 20px; height: 20px;" src="./imgs/searchIcon.png">
                            </el-button>
                          </el-input>
                        </div>
						<!-- 菜单栏 -->
						<div class="shadow"
							 style="width: 100%;overflow: hidden;background-color: #FFFFFF;margin-top: 2px;display: flex;justify-content: space-between;align-items: center; margin-bottom: 2px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);">
							<div style="display: flex;justify-content: flex-start;align-items: center;flex-wrap: wrap;">
								<div class="room_tab hand" v-if="room.myLv > 0" :class="room_tab===0?'room_type_bottom':''"
									 @click="set_room_tab(0, '-1')">全部
								</div>
								<div class="room_tab hand" :class="room_tab===1?'room_type_bottom':''"
									 @click="set_room_tab(1, '0')" style="color: #FF4646;">开放区
								</div>
								<div v-for="(item,index) in room_content_type" v-bind:key="index"
									 @click="set_room_tab(index+2, String(item.id), String(item.type), String(item.menuType), item.canWrite, item)" style="display: flex;">
									<div class="room_tab hand" :class="room_tab===index+2?'room_type_bottom':''">{{item.name }}
									</div>
								</div>
							</div>
						</div>


              <div v-if="menuShow">
                <div style="display: flex;gap:5px">
                  <div class="op sc hand new-tag" @click="handleDialogConfirm(1)">
                    <img src="./imgs/content.png" style="margin-right:10px;width: 16px;height: 16px; vertical-align: middle">
                    <span style="vertical-align: middle">上传文章</span>
                  </div>
                  <div class="op sc hand new-tag" @click="handleDialogConfirm(2)">
                    <img src="./imgs/img.png" style="margin-right:10px;width: 16px;height: 16px; vertical-align: middle">
                    <span style="vertical-align: middle">上传图片</span>
                  </div>
                  <div class="op sc hand new-tag" @click="handleDialogConfirm(3)">
                    <img src="./imgs/video.png" style="margin-right:10px;width: 16px;height: 16px; vertical-align: middle">
                    <span style="vertical-align: middle">上传视频</span>
                  </div>
                  <div class="op sc hand new-tag" @click="handleDialogConfirm(4)">
                    <img src="./imgs/file.png" style="margin-right:10px;width: 16px;height: 16px; vertical-align: middle">
                    <span style="vertical-align: middle">上传素材</span>
                  </div>
                </div>
              </div>
							<div v-else>
                <div style="display: flex;gap:5px">
                  <div v-if="room_tab > 1 && canWrite == 1" class="op sc hand" style="flex:1;background-color: #ffffff;margin-bottom:5px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);">
                    <div style="text-align: center;color: #2970FF;cursor: pointer;height: 100%;line-height: 46px;" @click="getMenuType">
                      <i class="el-icon-plus" style="font-size: 22px; font-weight: 900"></i>
                    </div>
                  </div>
                  <div v-if="classType == 3" class="op sc hand" style="flex:1;background-color: #ffffff;margin-bottom:5px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
                text-align: center;color: #2970FF;height: 46px;line-height: 46px">
                    <div v-if="tableViewModel" @click="tableViewModel = false">切换为普通视图</div>
                    <div v-if="!tableViewModel" @click="tableViewModel = true">切换为表格视图</div>
                  </div>

                  <div @click="clickDown" v-if="tableViewModel" class="op sc hand" style="flex:1;background-color: #ffffff;margin-bottom:5px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
                text-align: center;color: #2970FF;height: 46px;line-height: 46px">
                    导出数据
                  </div>
                </div>
              </div>


						<!-- content 表格浏览模式-->
						<div style="height:auto;max-width: 900px;margin: 0 auto;" v-if="tableViewModel && content_list.length > 0">
							<el-table :data="template_content_list" border style="width: 100%" table-layout="auto" :fit="true" :show-overflow-tooltip="true">
								<el-table-column type="index" width="50"></el-table-column>
								<el-table-column  v-for="item in template_content_args_list" :prop="item.id" :label="item.label" width="180" > </el-table-column>
								<el-table-column label="操作">
									<template slot-scope="scope">
										<el-button size="mini" @click="lookTemplate(scope)">查看</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>

						<!-- content 普通浏览模式 -->
						<div style="height:auto;max-width: 900px;margin: 0 auto;" v-if="!tableViewModel">
							<div style="width: 100%;">
								<div v-for="(item,index) in content_list" v-bind:key="index"
									 style="width: 100%;height: auto;">
									<template>
										<ContentAll :data="item" :showRoomName="false"></ContentAll>
									</template>
								</div>

								<div style="overflow:hidden;" v-if="show_skeleton">
									<div v-for="(item,index) in 10" v-bind:key="index" style="width: 100%;height: auto;">
										<el-skeleton style="width: 90%;background-color: white;padding: 5%; margin-bottom: 10px;" :loading="show_skeleton" animated>
											<template slot="template">
												<div style="overflow:hidden;">
													<el-skeleton-item variant="image" style="width: 60px; height: 60px;border-radius:30px;float: left;"/>
													<div style="float:left;display: flex; align-items: center; justify-content: space-between;">
														<el-skeleton-item variant="text" style="width: 50%;"/>
														<div style="display: flex; align-items: center; justify-content: space-between; margin-top: 16px; height: 16px;">
															<el-skeleton-item variant="text" style="width: 100px; margin: 12.5px 10px ; height:15px;"/>
															<el-skeleton-item variant="text" style="width: 30px; margin: 12.5px 10px ; height:15px;"/>
														</div>
													</div>
												</div>
												<el-skeleton-item variant="image" style="width: 90%; height: 210px;padding : 0 5%; float: left;margin-top: 10px;"/>
												<el-skeleton-item variant="text" style="width: 50%;margin-top: 16px; "/>
												<div style="display: flex; align-items: center; justify-content: space-between; margin-top: 16px; height: 16px;">
													<el-skeleton-item variant="text" style="margin-right: 16px;"/>
													<el-skeleton-item variant="text" style="width: 30%;"/>
												</div>
											</template>
										</el-skeleton>
									</div>
								</div>
							</div>
						</div>
					</div>

          <div v-if="!is_login" style="width: 100%; margin-top: 10px; height: 200px; background-color: #FFFFFF;">
              <div style="width: 100%; text-align: center;padding-top: 80px">请<span @click="utils.toAuth(0)" class="hand op" style="color: #2970FF; font-weight: 600">登录</span>后查看详情</div>
          </div>
				</div>
			</div>

			<div class="page_right" style="width:302px;float: right;">
				<div style="width:100%;height:auto;">
					<!-- room信息 -->
          <div style="width: 100%; background-color: #FFFFFF" :style="{backgroundImage: `url(${require('./imgs/' + roomUrl)})`}">
            <div style="position:relative;padding:15px 0px;margin-bottom: 10px;box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1600);">
              <div style=" border-top: 30px solid #031F88;border-right: 30px solid transparent;width:0px;height:0px; position:absolute;top:0;left:0;"></div>
              <div style=" overflow:hidden;">
                <div style="color:#000;margin-top:10px;color:#031F88;font-size:20px;font-weight:700;max-height:50px;line-height:25px; float:left;padding:0px 15px;">
                  ROOM信息
                </div>
              </div>
              <div style="width:90%;padding:5% 0;margin: 0 5%; color: #666666; border-bottom: 1px solid #BDBFC1;">
                <div style="font-weight: 600; margin-bottom: 20px">
                  <span>简介：</span>
                  <span v-if="room.detail.length>100">
                                    <span v-if="!show_more_detail">{{room.detail.slice(0,100)}}...</span><span v-if="show_more_detail">{{room.detail}}</span><span v-if="!show_more_detail" @click="show_more_detail=true">
									<i class="el-icon-arrow-down el-icon--down"></i></span><span class="hand op sc" style="margin-left: 10px;" @click="show_more_detail=false" v-if="show_more_detail">
									<i class="el-icon-arrow-up"></i></span>
                                </span>
                  <span v-else>
                                    {{room.detail}}
                                </span>
                </div>

                <div style="margin-bottom: 20px;" v-if="room.fRoom && room.roomType == 3">
                                <span style="color: #666666;font-size: 16px;margin-top: 10px; font-weight: 600">父Room：
                                </span>
                  <span class="contentType hand op" @click="toRoom(room.fid)">
                                    <i class="el-icon-s-home" style="margin-right: 5px;"></i>
						                        <span>{{room.fRoom}}</span>
                                </span>
                </div>

                <div style="margin-bottom: 20px">
                  <div v-if="room.tags.length>0"
                       style="color: #444444;font-size: 13px;margin-top: 10px; font-weight: 600">ROOM类型
                  </div>
                  <div :style="{backgroundColor: typeList.find(e => e.value == room.roomType).color}" style="width: 96px; border-radius: 5px; text-align: center; height:22px;line-height: 22px;margin-top: 4px; color: #FFFFFF;">{{typeList.find(e => e.value == room.roomType).name}}</div>
                </div>

                <div style="margin-bottom: 20px">
                  <div v-if="room.tags.length>0"
                       style="color: #444444;font-size: 13px;margin-top: 10px; font-weight: 600">ROOM标签
                  </div>
                  <div style="display: flex;flex-wrap: wrap;justify-content: flex-start;align-items: center;">
                    <div class="tags" v-for="(item, index) in room.tags" :key="index">
                      {{item}}
                    </div>
                  </div>
                </div>

                <div style="margin-bottom: 20px" v-if="room.industry.length>0">
                  <div style="color: #444444;font-size: 13px;margin-top: 10px; font-weight: 600">
                    Room关联行业
                  </div>
                  <div style="display: flex;flex-wrap: wrap;justify-content: flex-start;align-items: center;">
                    <div class="tag" v-for="(item, index) in room.industry" :key="index">
                      {{item.name}}
                    </div>
                  </div>
                </div>

                <div v-if="room.roomType == 3" style="margin: 10px 0;">
                  <span style="color: #444444;font-size: 13px;margin-top: 10px; font-weight: 600;">子ROOM列表：</span>
                  <span class="hand op" style="color: #2970FF;font-size: 13px; font-weight: 600;" @click="lookSubRoomList">点击查看</span>
                </div>

                <div>
                  <span style="color: #444444;font-size: 13px;margin-top: 10px; font-weight: 600;">其他ROOM关联情况：</span>
                  <span class="hand op" style="color: #2970FF;font-size: 13px; font-weight: 600;" @click="lookRelevanceList">点击查看</span>
                </div>

                <!--                            <div v-if="room.tags2.length>0" style="color: #444444;font-size: 13px;margin-top: 10px;">包含子项目</div>-->
                <!--                            <div style="display: flex;flex-wrap: wrap;justify-content: flex-start;align-items: center;">-->

                <!--                                <div class="tags" v-for="item in room.tags2" :key="item">-->
                <!--                                    {{item}}-->
                <!--                                </div>-->
                <!--                            </div>-->

                <!--                            <div v-if="room.tags3.length>0" style="color: #444444;font-size: 13px;margin-top: 10px;">需求行业</div>-->
                <!--                            <div style="display: flex;flex-wrap: wrap;justify-content: flex-start;align-items: center;">-->
                <!--                                <div class="tags" v-for="item in room.tags3" :key="item.name">-->
                <!--                                    {{item.name}}-->
                <!--                                </div>-->
                <!--                            </div>-->

                <!--                            <div v-if="room.relevanceRoom" style="margin-top: 20px;font-size: 13px;">ROOM所属机构：<span-->
                <!--                                    style="color: #2970FF;">{{relevanceRoom.name}}</span></div>-->
              </div>

              <div style="color:#666; margin-top:20px;white-space: pre-wrap;overflow: hidden;padding:0 20px;">
                <div style="overflow: hidden;color: #444444;font-size: 13px;">
                  <span style="font-weight: 600">ROOM创建人:</span>
                  <div style="margin: 0 0 20px 0">
                    <User :data="room.user"></User>
                  </div>
                </div>
                <div style="overflow: hidden;color: #444444;font-size: 13px; margin-bottom: 16px">
                  <span style="font-weight: 600">ROOM管理员: <span style="margin: 0 10px">{{room_user_list.adminNum}}人</span></span>
                  <span class="hand op sc" style="margin-left: 10px;" @click="more_lv2_user=true" v-if="!more_lv2_user"><i class="el-icon-arrow-down el-icon--down"></i></span>
                  <span class="hand op sc" style="margin-left: 10px;" @click="more_lv2_user=false" v-if="more_lv2_user==true"><i class="el-icon-arrow-up"></i></span>
                  <div v-show="more_lv2_user && room_user_list.adminNum > 0 " class="inner-container" ref="moreAdmin" style="padding:6px 0; max-height: 420px; overflow: auto">
                    <div v-for="(item,index) in room_user_list.adminList" :key="index" style="margin-bottom: 6px">
                      <User :data="item"></User>
                    </div>
                  </div>
                </div>
                <div style="overflow: hidden;margin-top:6px;color: #444444;font-size: 13px; margin-bottom: 16px">
                  <span style="font-weight: 600">ROOM内部成员: <span style="margin: 0 10px">{{room_user_list.userNum}}人</span></span>
                  <span class="hand op sc" @click="more_lv1_user=true" v-if="!more_lv1_user"><i class="el-icon-arrow-down el-icon--down"></i></span>
                  <span class="hand op sc" @click="more_lv1_user=false" v-if="more_lv1_user==true"><i class="el-icon-arrow-up"></i></span>
                  <div v-show="more_lv1_user && room_user_list.userNum > 0" class="inner-container" ref="moreUser" style="padding:6px 0; max-height: 420px; overflow: auto">
                    <div v-for="(item,index) in room_user_list.userList" :key="index" style="margin-bottom: 6px">
                      <User :data="item" btn_text="关注" :onClickBtn="() => { collection_user( item.uuid)}"></User>
                    </div>
                  </div>
                </div>
                <div style="overflow: hidden;margin-top:6px;color: #444444;font-size: 13px;">
                                <span style="font-weight: 600">ROOM关注者: <span style="margin: 0 10px">{{room_user_list.followNum}}人</span><span v-if="room_user_list.followNum > 0" class="hand op"
                                                                                                                                                   style="color:#2970FF; font-weight: 600" @click="fanShow = true"><i class="el-icon-arrow-right"></i>查看名单</span></span>
                </div>
              </div>
            </div>
          </div>


					<!-- 事件 -->
					<Event v-if="is_login" ref="eventList" :roomId="parseInt($route.params.id)" ></Event>
                    <div class="hand op" @click="showMoreAll('所有事件')" style="display: flex; justify-content: end; color: #2970FF; font-weight: 600; margin-bottom: 20px"><i class="el-icon-arrow-right" style="margin-right: 2px; line-height: 22px"></i>查看全部事件</div>

					<!--报名弹窗-->
					<el-dialog :destroy-on-close="true" :visible.sync="upShow" width="980px" :show-close="false" class="event-form" :close-on-click-modal="false" v-if="upShow == true">
						<div class="top">
                            <span class="contentType">
                                <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                                <span>{{room.name}}</span>
                            </span>
							{{eventName}}
							<img src="../expert/imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="closeEvent">
						</div>
						<div style="padding: 20px 40px">
							<SignForm v-if="eventType === 'a'" :key="id" @closeEvent="closeEvent"></SignForm>
							<VideoForm v-if="eventType === 'b'"></VideoForm>
							<ReserveForm v-if="eventType === 'c'" :key="id" :roomId="id"></ReserveForm>
							<CollectForm v-if="eventType === 'j'" :roomId="id" @closeEvent="closeEvent"></CollectForm>
              				<VideoSelect v-if="eventType === 'v'" @closeEvent="closeEvent" @setVideo="setVideo" :roomId="id"></VideoSelect>
                            <div v-if="eventType === 'f'" style="text-align: center">打卡事件暂时无法使用</div>
<!--							<PayForm v-if="eventType === 'k'"></PayForm>-->
						</div>
					</el-dialog>

					<!--更多报名事件-->
					<el-dialog :visible.sync="moreShow" width="1244px" :show-close="false" class="event-form">
						<div class="top" style="color: #FFFFFF; height: 50px; line-height: 50px" :style="{'background-image': 'url('+img+')'}">
							{{eventName}}
							<img src="../expert/imgs/cancel.png" style="width: 28px; height: 28px;position: absolute;right: 30px;top: 10px;cursor: pointer;" @click="moreShow = false">
						</div>
						<div>
							<MoreView :roomId="id" v-if="eventName == '活动事件'"></MoreView>
							<MoreDocView :roomId="id" v-if="eventName == '付费文档'"></MoreDocView>
						</div>
					</el-dialog>

                    <!--更多报名事件-->
                    <el-dialog :visible.sync="moreShowAll" width="1244px" :show-close="false" class="event-form">
                        <div class="top" style="height: 50px; line-height: 50px">
                          {{eventName}}
                          <img src="../expert/imgs/cancel.png" style="width: 28px; height: 28px;position: absolute;right: 30px;top: 10px;cursor: pointer;" @click="moreShowAll = false">
                        </div>
                        <div>
                        <MoreAll :roomId="id"></MoreAll>
                        </div>
                    </el-dialog>

					<!--发布弹窗-->
					<el-dialog title="菜单发布" :visible.sync="showInput">
						<div>
							<Input :roomId="id" :menuType="menuType" :menuId="menuId" @custom-event="closeInputModule"></Input>
						</div>
					</el-dialog>

					<!--口令-->
					<el-dialog title="输入口令加入Room" :visible.sync="showCommand" width="380px">
						<div>
							<el-input
									v-model="command"
									:show-word-limit="true"
									maxlength="12"
									placeholder="输入正确的口令">
							</el-input>
						</div>

						<div style="display: flex; justify-content: end; margin-top: 10px">
							<el-button type="primary" size="small" @click="commandJoin">确定</el-button>
						</div>
					</el-dialog>

					<!--问答弹窗-->
					<el-dialog :visible.sync="dialogShow" width="978px" :close-on-click-modal="false">
						<Askquestion v-if="dialogShow" @close="hideChild" :awType="answerType" :roomId="$route.params.id" :form="2"></Askquestion>
					</el-dialog>

					<!--关注者名单-->
					<el-dialog :visible.sync="fanShow" width="700px" :show-close="false" class="event-form">
						<div class="top">
                            <span class="contentType">
                                <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                                <span style="max-width: 200px; vertical-align: top; display: inline-block; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{room.name}}</span>
                            </span>
							Room关注者名单
							<img src="../expert/imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="fanShow = false">
						</div>
						<div style="padding: 20px 40px; display: flex; flex-wrap: wrap; justify-content: space-between">
							<div v-for="(item, index) in room_user_list.followList" :key="index" style="width: 260px; height: 56px; padding: 16px 20px; border: 1px #DADBDE solid; border-radius: 5px; margin-bottom: 10px">
								<User :data="item"></User>
							</div>
						</div>
						<div style="padding-bottom: 20px;text-align: center;">
						  <el-pagination
							  background
							  @current-change="getUseFollow"
							  :current-page.sync="followPage"
							  :page-size="10"
							  layout="prev, pager, next"
							  :total="room_user_list.followNum">
						  </el-pagination>
						</div>
					</el-dialog>

					<!--关联Room-->
					<el-dialog :visible.sync="showRelevance" width="722px" :show-close="false" class="event-form">
						<div class="top">
                            <span class="contentType">
                                <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                                <span style="max-width: 200px; vertical-align: top; display: inline-block; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{room.name}}</span>
                            </span>
							关联Room列表
							<img src="../expert/imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showRelevance = false">
						</div>
            <div style="padding: 20px 40px; display: flex; flex-wrap: wrap;">
                <div v-for="(item, index) in associateList" :key="index" style="width: 200px; margin-left: 10px; margin-bottom: 10px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);border-radius: 5px">
                  <div style="border-radius: 5px;">
                    <div v-if="room.roomType == 3 && (room.myLv === 1 || room.myLv === 2)" @click="cancelRelevance(item.id)" class="hand op" style="font-size: 12px;color: #989898;margin: 10px 10px 0 0; display: flex; justify-content: end">解除关联<i class="el-icon-unlock" style="font-size: 14px; margin-left: 4px; color: #2970FF"></i></div>
                    <div class="hb hand op" style="padding: 6px 17px;" @click="toRoom(item.id)">
                      <img class="room_head" :src="item.cover">
                      <div class="room_name">{{item.name}}</div>
                    </div>
                    <div class="hb" style="margin-top:10px;background: #2970FF;height: 24px;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;box-sizing: border-box;padding: 0 20px;font-size: 12px;color: #FFFFFF;">
                      <div>关注 {{item.fansCount}}</div>
                    </div>
                  </div>
                </div>
              </div>
            <div style="display: flex; justify-content: center; width: 100%; padding-bottom: 30px">
              <div class="notice-btn hand op" @click="lastChange" style="margin-right: 20px">上一页</div>
              <div class="notice-btn hand op" @click="nextChange">下一页</div>
            </div>

					</el-dialog>

					<!--子Room-->
					<el-dialog :visible.sync="showSubRoom" width="38%" :show-close="false" class="event-form">
						<div class="top">
                            <span class="contentType">
                                <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                                <span>{{room.name}}</span>
                            </span>
							子Room列表
							<img src="../expert/imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showSubRoom = false">
						</div>
						<div style="padding: 20px 40px; display: flex; flex-wrap: wrap;">
							<div v-for="(item, index) in subRoomList" :key="index" style="width: 200px; margin-left: 10px; margin-bottom: 10px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);border-radius: 5px">
								<div style="border-radius: 5px;">
									<div class="hb hand op" style="padding: 6px 17px;" @click="toRoom(item.id)">
										<img class="room_head" :src="item.cover">
										<div class="room_name">{{item.name}}</div>
									</div>
									<div class="hb" style="margin-top:10px;background: #2970FF;height: 24px;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;box-sizing: border-box;padding: 0 20px;font-size: 12px;color: #FFFFFF;">
										<div>关注 {{item.fansCount}}</div>
									</div>
								</div>
							</div>
						</div>
            <div style="display: flex; justify-content: center; width: 100%; padding-bottom: 30px">
              <div class="notice-btn hand op" @click="lastChange" style="margin-right: 20px">上一页</div>
              <div class="notice-btn hand op" @click="nextChange">下一页</div>
            </div>
					</el-dialog>
				</div>
			</div>
		</div>

<!--		ROOM日历-->
		<el-dialog width="1000px" title='ROOM日历' center :visible.sync='CalendarShow' class="calendar-form" top="6vh">
		  <Calendar :roomId="room.id"></Calendar>
		</el-dialog>

		<!--选择知识仓库类型-->
		<el-dialog :visible.sync="dialogVisible" title="" :close-on-click-modal="false">
			<div  class="bottom">
				<div  class="btns">
					<div class="btn" @click="handleDialogConfirm(1)">
						<img src="./imgs/content.png" style="margin-right:10px;width: 16px;height: 16px;">
						文章
					</div>
					<div class="btn op sc hand"   @click="handleDialogConfirm(2)" slot="popover">
						<img  src="./imgs/img.png" style="margin-right:10px;width: 16px;height: 16px;">
						图片
					</div>
					<div class="btn op sc hand" @click="handleDialogConfirm(3)">
						<img src="./imgs/video.png" style="margin-right:10px;width: 16px;height: 16px;">
						视频
					</div>
					<div class="btn op sc hand" @click="handleDialogConfirm(4)">
						<img  src="./imgs/file.png" style="margin-right:10px;width: 16px;height: 16px;">
						文件
					</div>
				</div>
			</div>
		</el-dialog>

		<!--发布知识仓库菜单图片-->
		<div v-if="kwImagesShow == true">
			<add-image-file-base-form :is-show="kwImagesShow" :roomId = parseInt(this.$route.params.id) :menuId = parseInt(this.menuId) :editId="0" :on-close="closeImageModule"></add-image-file-base-form>
		</div>

		<!--发布知识仓库菜单视频-->
		<el-dialog :close-on-click-modal="false" width="482px" :visible.sync="kwVideoShow" :show-close=false class="upload_att">
			<addUploadVideo :roomId = parseInt(this.$route.params.id) :menuId = parseInt(this.menuId) :editId="0" @custom-event="closeViodeModule"></addUploadVideo>
		</el-dialog>

		<!--发布知识仓库菜单附件-->
		<el-dialog :visible.sync="kwEnclosureShow" width="705px" :close-on-click-modal="false">
			<addUploadAttachment :roomId = parseInt(this.$route.params.id) :menuId = parseInt(this.menuId) :info=uploadAttachmentInfo :editId="0" @custom-event="closeEnclosureModule"></addUploadAttachment>
		</el-dialog>

		<!--发布知识仓库格式图片-->
		<div v-if="menuImagesShow == true">
			<menu-image :is-show="menuImagesShow" :roomId = parseInt(this.$route.params.id) :menuId = parseInt(this.menuId) :editId="0" :on-close="closeImageModule"></menu-image>
		</div>

		<!--发布知识仓库格式视频-->
		<el-dialog :close-on-click-modal="false" width="482px" :visible.sync="menuVideoShow" :show-close=false class="upload_att">
			<menu-video :roomId = parseInt(this.$route.params.id) :menuId = parseInt(this.menuId) :editId="0" @custom-event="closeViodeModule"></menu-video>
		</el-dialog>

		<!--发布知识仓库格式附件-->
		<el-dialog :visible.sync="menuEnclosureShow" width="730px" :close-on-click-modal="false" :show-close=false>
			<menu-enclosure :roomId = parseInt(this.$route.params.id) :menuId = parseInt(this.menuId) :info=uploadAttachmentInfo :editId="0" @custom-event="closeEnclosureModule"></menu-enclosure>
		</el-dialog>

        <el-dialog :visible.sync="showTemplate" width="900px" :close-on-click-modal="false" style="padding: 30px 20px 10px;">
            <!--招标信息  -->
            <pro-ject :roomId="parseInt(this.$route.params.id)" :type="1" v-if="templateType === 'proJect'" @close="buSinessclose"></pro-ject>
            <!--采购信息  -->
            <pur-chase :roomId="parseInt(this.$route.params.id)" :type="2" v-if="templateType === 'purChase'" @close="buSinessclose"></pur-chase>
            <!--招募信息  -->
            <rec-ruit :roomId="parseInt(this.$route.params.id)"  :type="3" v-if="templateType === 'recRuit'" @close="buSinessclose"></rec-ruit>
            <!--商务信息  -->
            <bu-siness :roomId="parseInt(this.$route.params.id)" :type="4" v-if="templateType === 'buSiness'" @close="buSinessclose"></bu-siness>
            <!--招商信息  -->
            <inves-tment :roomId="parseInt(this.$route.params.id)" :type="5" v-if="templateType === 'invesTment'" @close="buSinessclose"></inves-tment>
            <!--需求信息  -->
            <de-mand :roomId="parseInt(this.$route.params.id)" :type="6" v-if="templateType === 'deMand'" @close="buSinessclose"></de-mand>
        </el-dialog>

        <!--分享room二维码  -->
        <el-dialog :close-on-click-modal="false" :visible.sync="wxshareDialong" width="600px" >
            <qrCode :onClose="function (){wxshareDialong = false}"></qrCode>
        </el-dialog>
	</div>
</template>

<script>
	import Head from "@/components/common/Head";
	import RoomNav from './components/RoomNav'
	import User from "@/components/common/User.vue";
	import ContentAll from './components/ContentAll'
	import Input from './components/Input'
	import InfoOrder from "./components/InfoOrder";
    import SignForm from "@/components/common/signUp/SignForm.vue";
	import VideoForm from "./components/event/videoForm";
	import ReserveForm from "./components/event/reserveForm";
	import CollectForm from "./components/event/collectForm";
	import Event from "./components/Event";
	import MoreView from "./components/event/moreView";
	import MoreDocView from "./components/event/moreDocView";
	import Askquestion from "@/components/common/Askquestion";
	import Question from "./components/Question";
	import Information from "./components/Information";
	import Live from "@/components/common/Live.vue";
  	import VideoSelect from "@/pages/room/components/event/videoSelect.vue";
  	import Calendar from "@/components/common/calendar/Calendar.vue";
  	import menuImage from "./components/menuModule/menuImage";
  	import menuVideo from "./components/menuModule/menuVideo";
  	import menuEnclosure from "./components/menuModule/menuEnclosure";
  	import addImageFileBaseForm from "./knowledge/components/addImageFileBaseForm";
  	import addUploadVideo from "./knowledge/components/addUploadVideo";
	import addUploadAttachment from "./knowledge/components/addUploadAttachment";
    import Navigation from "@/pages/room/navigation.vue";
    import MoreAll from "@/pages/room/components/event/MoreAll.vue";
    import ProJect from "@/components/common/tempLate/proJect.vue";
    import PurChase from "@/components/common/tempLate/purChase.vue";
    import RecRuit from "@/components/common/tempLate/recRuit.vue";
    import BuSiness from "@/components/common/tempLate/buSiness.vue";
    import InvesTment from "@/components/common/tempLate/invesTment.vue";
    import DeMand from "@/components/common/tempLate/deMand.vue";
    import qrCode from "./components/qrCode.vue";
	export default {
		components: {
            DeMand,
            InvesTment,
            BuSiness,
            RecRuit,
            PurChase,
            ProJect,
            MoreAll,
            Navigation,
            Calendar,
            VideoSelect,
			Live,
			Information,
			Question,
			Askquestion,
			MoreView,
			MoreDocView,
            Event,
			CollectForm,
			ReserveForm,
			VideoForm,
			SignForm,
			InfoOrder,
			Head,
			RoomNav,
			ContentAll,
			Input,
			User,
			menuImage,
			menuVideo,
			menuEnclosure,
			addImageFileBaseForm,
			addUploadVideo,
			addUploadAttachment,
            qrCode
		},
		computed: {
			user() {
				return this.$store.state.user
			},
			is_login() {
				return this.$store.state.is_login
			},
		    call() {
			    return this.$store.state.videoCall
		    }
		},
		data() {
			return {
              pageWin: 1,
              showTemplate: false,
              templateType: '',
              cachedComponents: ['RoomNav'],
              roomUrl: '',
              typeList: [{
                  value: 1,
                  color: '#2970FF',
                  url: 'projectRoom.png',
                  name: '项目ROOM'
                },
                {
                  value: 2,
                  color: '#FF9800',
                  url: 'interest.png',
                  name: '兴趣ROOM'
                },
                {
                  value: 3,
                  color: '#031F88',
                  url: 'organ.png',
                  name: '机构ROOM'
                },
                {
                  value: 4,
                  color: '#69BF29',
                  url: 'manage.png',
                  name: '经营ROOM'
                },],
                content: '',
                selectType: 0,
				loading: true,
				living:false,
				role:"",
				joinLiveId:"",
				CalendarShow:false,
				classType:0,
				fanShow: false,
				tableViewModel:false,
				// 事件
				upShow: false,
				moreShow: false,
                moreShowAll: false,
				img: '',
				eventName: '',
				eventType: '',
				menuType: '',
				showInput:false,
				menuId: '-1',
				canWrite: 0,
				id: "0",
				page: 1,
				like: '',
				more: 1,
				room_tab: 0,
				room_content_type_id: "all",
				room_add_type_id: "0",
				class_id: "3",
				tz_work_type: "0",
				content_list: [],
				template_content_list:[],
				template_content_args_list:[],
				more_lv2_user: false,
				more_lv1_user: false,
				show_more_detail: false,
				show_skeleton: false,
				show_input: false,
				room: {
					name: '',
					cover: '',
					headIco: '',
					relevanceRoom: '',
					click_number: '',
					collection_number: '',
					content_number: '',
					is_collection: '',
					room_content_type_add: [],
					tags: [],
					myLv: 0,
					detail: '',
					user: {},
					industry: [],
				},
				content_type_list: [],
				room_content_type: [],
				room_user_list: {
					adminList: [],
					userList: []
				},
				ask_list: [],
				work_list: '',
				menuInfo:{},

				// 单选商家/专家
				showOrder: "content",

				// 口令
				showCommand: false,
				command: '',

				// 问答
				answerType: '',
				dialogShow: false,

				// 机构关联Room
				associateList: [],
				showRelevance: false,

				// 子Room列表
				subRoomList: [],
				showSubRoom: false,
				NearbyCalendarEventList:[],

				// 分页
				followPage: 1,
				userPage: 2,
				userMore: 1,
				adminPage: 2,
				adminMore: 1,
				//知识仓库菜单
				kwImagesShow:false,
				dialogVisible: false,
				kwVideoShow:false,
				kwEnclosureShow:false,
				uploadAttachmentInfo:{
					id:0,
					auth:1,
					material_name:"",
					files:[],
					types:""
				},
                menuShow:0,
				//知识仓库格式
				menuImagesShow:false,
				menuVideoShow:false,
				menuEnclosureShow:false,

                //分享
                wxshareDialong:false,

			}
		},

		watch: {
			//监听相同路由下参数变化的时候，从而实现异步刷新
			'$route'() {
				this.backTop();
				this.showOrder = 'content';
                this.menuInfo = {};
				this.id = this.$route.params.id;
				this.like = '';
				this.page = 1;
                this.more = 1;
				this.followPage = 1;
				this.userPage = 2;
				this.userMore = 1;
				this.adminPage = 2;
				this.adminMore = 1;
				this.room_content_type_id = "all";
				this.content_list = [];
				this.template_content_list = [];
				this.room_content_type = [];
				this.get_room_info();
				this.get_room_user_list();
				this.getTodayCalendarEventList();
                if (this.is_login) {
                  this.getMenuList();
                }
				this.getRoomLive();
			},
		},
		created() {
			this.$EventBus.$on("roomContentFresh", ({ }) => {
                this.$nextTick(() => {
                    this.page = 1;
                    this.more = 1;
                    this.content_list = []
                    this.get_content_list();
                });
			});
		},
		mounted: function () {
			var that = this;
			this.id = this.$route.params.id;
			this.backTop();
			this.get_room_info();
			this.get_room_user_list();
			this.getTodayCalendarEventList();
            if (this.is_login) {
              this.getMenuList();
            }
			this.getRoomLive();

			this.$EventBus.$on("LiveFinish",function (res){
				if (res.roomId == that.room.id){
					that.utils.toast("直播结束");
					that.living = false;
					that.role = false;
					that.joinLiveId = false;
				}
			})

			this.$EventBus.$emit("close_im", {is_close: 1});
			this.$nextTick(function () {
				this.$EventBus.$on('changeData', () => {
					this.content_list = [];
					this.template_content_list = [];
					this.showInput = false;
					this.page = 1;
					this.more = 1;
					this.get_content_list();
				});
			});

            window.onscroll = function () {
                //变量scrollTop是滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

                //变量windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

                let nember = scrollHeight - scrollTop - windowHeight;
                if (nember <= 10 && nember > 0) {
                  if (that.more == 1 && that.show_skeleton == false) {
              if(that.content == ''){
                that.get_content_list();
              }else{
                that.getSearchContent();
              }
                  }else {
                     console.log("已加载完");
                  }
                }
             }

            //监听支付回调广播
            this.$EventBus.$EventBus.$off("payMsg");
            this.$EventBus.$on("payMsg", (data) => {
                this.$nextTick(() => {
                    that.closeLoading();
                    if (data.code == 100 ) {
                        that.utils.sus("发布成功");
                        that.dialogShow = false;
                        that.$refs.childQuestion.refreshQuestion();
                    }else if(data.code == 101){
                        that.utils.err(data.msg);
                    }
                });
            });

		},

		directives: {
			drag(el, bindings) {
				console.log(bindings);
				el.onmousedown = function (e) {
					var disx = e.pageX - el.offsetLeft;
					var disy = e.pageY - el.offsetTop;
					document.onmousemove = function (e) {
						el.style.left = e.pageX - disx + 'px';
						el.style.top = e.pageY - disy + 'px'
					};
					document.onmouseup = function () {
						document.onmousemove = document.onmouseup = null
					}
				}
			}
		},
		methods: {
            //关闭分享二维码弹窗
            qrCodeClost(){
                this.wxshareDialong = false;
            },
            //关闭模版信息弹窗
            buSinessclose(type) {
                this.showTemplate = false;
                this.templateType = '';
                //发送广播刷新模版信息
                this.$EventBus.$emit("refreshQuestion", {type: type});
            },
            //ask @事件
            hideChild(data){
                var that= this;
                that.dialogShow = false;
                //没有开启悬赏的直接广播刷新，带悬赏的需要支付广播去刷新
                if(data.isReward == 0){
                    that.$refs.childQuestion.refreshQuestion();
                }
            },


          // 搜索room内容
          roomSearch(){
            this.page = 1;
            this.more = 1;
            this.content_list = [];
            this.template_content_list = [];
            if(this.content == ''){
              this.get_content_list();
            }else{
              this.getSearchContent();
            }
          },
          getSearchContent(){
                var that = this;
                that.show_skeleton = true;
                if (that.page == 1){
                  that.template_content_args_list = [];
                }
                this.newApi.roomContentSearch({
                  content:this.content,
                  pageNo:this.page,
                  menuId:this.menuId,
                  roomId:this.id,
                  range:this.selectType,
                  pageSize:10
                }).then(ret =>{
                  if (that.classType && that.classType == 3 && ret.data.length > 0 ){
                    //处理模版文章 表格模式  字段
                    var argList = ret.data[0].data.list;
                    for (let i = 0; i < argList.length; i++) {
                      //表格视图
                      if (argList[i].layoutArray && argList[i].layoutArray.length > 0){
                        var layoutArray = argList[i].layoutArray;
                        for (let j = 0; j < layoutArray.length; j++) {
                          var tr = layoutArray[j];
                          for (let k = 0; k < tr.length; k++) {
                            var td = tr[k];
                            for (let l = 0; l < td.columns.length; l++) {
                              var arg = td.columns[l];
                              if (["divider","link","text","simpleImage","space"].indexOf(arg.compType) == -1){
                                that.template_content_args_list.push(arg);
                              }
                            }
                          }
                        }
                      }else

                          //栅格视图
                      if (argList[i].columns && argList[i].columns.length > 0){
                        for (let j = 0; j < argList[i].columns.length; j++) {
                          var column = argList[i].columns[j];
                          for (let k = 0; k < column.list.length; k++) {
                            var arg = column.list[k];
                            if (["divider","link","text","simpleImage","space"].indexOf(arg.compType) == -1){
                              that.template_content_args_list.push(arg);
                            }
                          }
                        }
                      }

                      //其他的
                      else{
                        if (["divider","link","text","simpleImage","space"].indexOf(argList[i].compType) == -1){
                          that.template_content_args_list.push(argList[i]);
                        }
                      }
                    }

                    console.log("template_args_content_list");
                    console.log(argList);
                  }

                  for (let i = 0; i < ret.data.length; i++) {
                    if (ret.data[i].menuType === 2) {
                      if (ret.data[i].content.images.length > 0) {
                        ret.data[i].menuType = 3
                      }
                      if (ret.data[i].content.videos.length > 0) {
                        ret.data[i].menuType = 4
                      }
                      if (ret.data[i].content.enclosure.length > 0) {
                        ret.data[i].menuType = 5
                      }
                    }
                    that.content_list.push(ret.data[i]);
                    if (that.classType && that.classType == 3){
                      //处理模版文章 表格模式 内容
                      const content = JSON.parse(JSON.stringify(ret.data[i].content));
                      for (const item in content) {
                        if (typeof content[item] == "string"){
                          content[item] = that.toText(content[item],20);
                        }
                      }
                      that.template_content_list.push(content);
                    }
                  }
                  if (ret.data.length < 10) {
                    that.more = 0;
                  }
                  that.page++;
                  that.show_skeleton = false;
                })
      },

			//关闭标准发布
			closeInputModule(){
				this.showInput = false;
			},
			//关闭添加图片组件
			closeImageModule(){
				this.kwImagesShow = false;
				this.menuImagesShow = false;
			},
			//关闭添加视频组件
			closeViodeModule() {
				// 在父组件中监听自定义事件
				this.kwVideoShow = false;
				this.menuVideoShow = false;
			},
			//关闭添加附件组件
			closeEnclosureModule(){
				this.kwEnclosureShow = false;
				this.menuEnclosureShow = false;
			},
			handleDialogConfirm(selectedProfession) {
				if(selectedProfession == 1){
					this.$router.push({path: '/knowledge/KwArticle/0', query: {roomId: this.$route.params.id,menuId: this.menuId}});
				}else if(selectedProfession == 2){
					this.kwImagesShow = true;
				}else if(selectedProfession == 3){
					this.kwVideoShow = true;
				}else if(selectedProfession == 4){
					this.kwEnclosureShow = true;
				}
				// Close the dialog
				this.dialogVisible = false;

			},
			getRoomLive(){
				const that = this;
				this.newApi.getRoomLive({roomId: this.$route.params.id}).then(res =>{
					if (res.isSuccess === 1 && res.data != null) {
						that.joinLiveId = res.data.liveId;
						that.role = "audience";
						that.living = true;
					}
				})
			},
			getTodayCalendarEventList() {
				var that = this;
				this.newApi.getTodayCalendarEventList({roomId: this.$route.params.id}).then(res =>{
					if (res.isSuccess === 1 && res.data.length > 0) {
						that.$alert(that.room.name+" 今日有"+res.data.length+"个ROOM日历备忘", '温馨提示', {
							confirmButtonText: '点击查看',
						}).then(() => {
              				that.CalendarShow = true;
            			})
					}
				})
			},
			// getNearbyCalendarEventList(){
			// 	var that = this;
			// 	this.newApi.getNearbyCalendarEventList({roomId: this.$route.params.id, type: 2}).then(res =>{
			// 		if (res.isSuccess === 1) {
			// 			that.NearbyCalendarEventList = res.data;
			// 		}
			// 	})
			// },
			lookTemplate(scope){
				this.$router.push({
					name: 'View',
					query: {
						id: this.content_list[scope.$index].menuType,
						type: this.content_list[scope.$index].type,
						contentId: this.content_list[scope.$index].id
					}
				})
			},

      // 关注者分页
      getUseFollow() {
          let params = {};
          params.page = this.followPage;
          params.pageSize = 10;
          params.roomId = this.id;
          this.newApi.getRoomUserList(params).then(res => {
              this.room_user_list.followList = res.data.followList
          })
      },

      // 成员分页
      getUser() {
        let params = {};
        // params.page = this.userPage;
        // params.pageSize = 10;
        params.roomId = this.id;
        this.newApi.getRoomUserList(params).then(res => {
          // for (const item of res.data.userList) {
          //   this.room_user_list.userList.push(item)
          // }
          // if (res.data.userList.length < 10) {
          //   this.userMore = 0;
          // } else {
          //   this.userPage ++;
          // }
          this.this.room_user_list.userList = res.data.userList

        })
      },
      // 管理员分页
      getAdmin() {
			let params = {};
			// params.page = this.adminPage;
			// params.pageSize = 10;
			params.roomId = this.id;
			this.newApi.getRoomUserList(params).then(res => {
			  // for (const item of res.data.adminList) {
			  //   this.room_user_list.adminList.push(item)
			  // }
			  // if (res.data.adminList.length < 10) {
			  //   this.adminMore = 0;
			  // } else {
			  //   this.adminPage ++;
			  // }
			  this.room_user_list.adminList = res.data.adminList
			})
		  },

			// 取消关联Room
			cancelRelevance(id) {
				this.newApi.cancelRoomRelevance({roomId: this.$route.params.id, cancelRoomId: id}).then(res =>{
					if (res.isSuccess === 1) {
						this.utils.sus(res.data);
						this.lookRelevanceList()
					}
				})
			},

      getRelevanceList() {
        this.newApi.associateTheRoomList({roomId: this.$route.params.id, page: this.pageWin, pageSize: 12}).then(res =>{
          if (res.data.length === 0 && this.pageWin !== 1) {
            this.utils.msg('已是最后一页了')
            this.pageWin --
          } else {
            this.associateList = res.data;
          }
        })
      },

			// 获取关联room（机构room使用）
			lookRelevanceList() {
        this.pageWin = 1
        this.showRelevance = true
				this.getRelevanceList()
			},

      // 上一页
      lastChange() {
        if (this.showRelevance === true) {
          if (this.pageWin === 1) {
            this.utils.msg('已是第一页')
          } else {
            this.pageWin --;
            this.getRelevanceList()
          }
        }
        if (this.showSubRoom === true) {
          if (this.pageWin === 1) {
            this.utils.msg('已是第一页')
          } else {
            this.pageWin --;
            this.getSubRoomList()
          }
        }

      },

      // 下一页
      nextChange() {
        if (this.showRelevance === true) {
          if (this.associateList.length < 12) {
            this.utils.msg('已是最后一页')
          } else {
            this.pageWin ++;
            this.getRelevanceList()
          }
        }
        if (this.showSubRoom === true) {
          if (this.subRoomList.length < 12) {
            this.utils.msg('已是最后一页')
          } else {
            this.pageWin ++;
            this.getSubRoomList()
          }
        }

      },


			// 获取子Room列表（机构room使用）
			lookSubRoomList() {
        this.pageWin = 1
        this.showSubRoom = true
				this.getSubRoomList()
			},
      getSubRoomList() {
        this.newApi.getRoomSubList({roomId: this.$route.params.id, page: this.pageWin, pageSize: 12}).then(res =>{
          if (res.data.length === 0 && this.pageWin !== 1) {
            this.utils.msg('已是最后一页了')
            this.pageWin --
          } else {
            this.subRoomList = res.data;
          }
        })
      },

			// 进入room详情
			toRoom(id) {
				this.showRelevance = false;
				this.utils.a('/room/info/' + id)
			},

			// 商务信息
			business(e) {
        if (!this.utils.toAuth(1)) {
          return false;
        }
                if (e === 'recRuit' || e === 'buSiness' || e === 'invesTment') {
                    if (this.user.userType === 2) {
                        this.showTemplate = true;
                        this.templateType = e;
                    }else {
                        this.utils.err('只有机构账号才能发布相关信息');
                        return;
                    }
                }
                this.showTemplate = true;
                this.templateType = e;
			},

			// 红包问答
			answer(type){
				var that = this;
				if(that.user.realNameStatus == 0 || that.user.realNameStatus == 1){
				  that.utils.realNameAuthJump(that.user.uuid,that.user.realNameStatus,that.user.userType);
				  return;
				} else {
				  that.answerType = type;
				  that.dialogShow = true;
				}
			},

			//关闭弹窗
			colseRelese(){
				var that = this;
				that.dialogShow = false;
			},

			// 口令加入room
			commandJoin() {
				this.newApi.passwordJoinRoom({roomId: this.$route.params.id, password: this.command}).then(res => {
					if (res.isSuccess === 1) {
						this.utils.sus(res.data);
						this.showCommand = false;
						this.command = '';
						this.content_list = [];
						this.template_content_list = [];
						this.room_tab = 0;
						this.page = 1;
						this.more = 1;
						this.get_content_list();
						this.get_room_info();
						this.getMenuList();
					}
				})
			},

			showMoreEvent(event) {
				this.eventName = event;
				this.img = require('./imgs/title1.png');
				this.moreShow = true
			},

      showMoreAll(event) {
        this.eventName = event;
        this.moreShowAll = true;
      },

			showMoreDocEvent(event) {
				this.eventName = event;
				this.img = require('./imgs/title5.png');
				this.moreShow = true
			},
			// 关闭事件弹窗
			closeEvent(data) {
				this.upShow = false;
                //刷新活动报名
                if(data.eventType == 'a'){
                    this.$refs.eventList.getEnrollList();
                }
                //刷新付费文档
                if(data.eventType == 'j'){
                    this.$refs.eventList.getDocList();
                }

			},

			// 选择二级菜单
			checkMenuLv(e, index) {
				this.set_room_tab(index, String(e.id), String(e.type) , String(e.menuType))
			},

			// 获取菜单发布
			getMenuType() {
				// if(this.menuInfo.isKwFormat == 0){
					if (this.menuType < 6 ) {
						switch (this.menuType) {
							case '1':
								this.$router.push({name: 'Acticle', query: {id: this.$route.params.id, menuId: this.menuId}});
								break;
							case '2':
								this.showInput = true;
								break;
							case '3':
								this.menuImagesShow = true;
								break;
							case '4':
								this.menuVideoShow = true;
								break;
							case '5':
								this.menuEnclosureShow = true;
								break;
							default:
								this.$router.push({name: 'View', query: {id: this.menuType,menuId: this.menuId,roomId: this.$route.params.id}});
						}
					}else {
						this.$router.push({name: 'View', query: {
								id: this.menuType,
								menuId: this.menuId,
								roomId: this.$route.params.id
							}})
					}
				// }else if(this.menuInfo.isKwFormat == 1){
				// 	this.dialogVisible = true;
				// }

			},

			// 获取room菜单
			getMenuList() {
				this.newApi.getRoomMenuList({roomId: this.$route.params.id}).then(res => {
					this.room_content_type = res.data.allMenu
				})
			},

			// 编辑Room信息
			editRoomInfo(id) {
				this.utils.a('/room/edit/' + id)
			},

			// 切换单选
			checkRadio(e) {
				if (e === this.showOrder) {
					this.showOrder = 'content'
				} else {
					this.showOrder = e
				}
			},

			backTop() {
				document.documentElement.scrollTop = document.body.scrollTop = 0;
			},

			input(item) {
				if (item.class_id == 13 || item.class_id == 8) {
					window.open("/content/add2/?room_id=" + this.id + "&class_id=" + item.class_id + "&room_content_type_id=" + item.id, '_blank');
					return;
				}
				this.class_id = item.class_id;
				this.show_input = true;
				this.room_add_type_id = item.id;
				if (typeof this.tz_work_type != 'undefined') {
					this.tz_work_type = item.work_type;
				}
			},
			close_bg_shadow() {
				this.show_input = false;
			},
			share_room() {
				this.$EventBus.$emit("share_room", {room_id: this.id});
			},
			set_room_tab: function (index, id, classType, menuType, canWrite, item) {
				var that = this;
                if (item) {
                    that.menuInfo = item;
                    that.menuShow = that.menuInfo.isKwFormat;
                }else{
                    that.menuShow = 0;
                }
				that.tableViewModel = false;
				that.more = 1;
				that.room_tab = index;
				that.page = 1;
				that.content_list = [];
				that.template_content_list = [];
				if (index == 0 || index == 1) {
					that.room_content_type_id = id;
					that.menuId = id;
					that.classType = '';
					that.menuType = '';
                    that.get_content_list();
				} else {
					that.room_content_type_id = that.room_content_type[index - 2].id;
					that.menuId = id;
					that.canWrite = canWrite;
					that.classType = classType;
					that.menuType = menuType;
                     that.get_content_list();
				}
			},

			toText(html,num){
				console.log(html);
				const processedText = html
						.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '') // Remove style, script, iframe tags and content
						.replace(/<[^>]+?>/g, '') // Remove other HTML tags
						.replace(/&nbsp;/gi, ' ') // Replace &nbsp; with a space
						.replace(/\s+/g, ' ')// Replace multiple spaces with a single space
						.replace(/&ldquo;/gi, '"')
						.replace(/&rdquo;/gi, '"')
						.replace(/\.(jpg|jpeg|png|gif)$/i,'') // 过滤文章显示图片的正则
						.replace(/https?:\/\/[^\s]+\.(jpg|jpeg|png|gif)/gi,'') //过滤文章显示视频的正则
						.trim();
				// Remove leading and trailing spaces
				if (processedText.length > 20){
					return processedText.substring(0, num)+'...';
				}else{
					return processedText;
				}

			},
			/**
			 * 生成excel并下载
			 * @param str 数据
			 * @param fileName 生成文件名
			 */
			downLoadExcel (str, fileName) {
				// //定义表头
				// let str = title;
				// //增加\t为了不让表格显示科学计数法或者其他格式
				// for(let i = 0 ; i < data.length ; i++ ){
				// 	for(let item in data[i]){
				// 		str+=`${data[i][item] + '\t'},`;
				// 	}
				// 	str+='\n';
				// }
				//encodeURIComponent解决中文乱码
				let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str);
				//通过创建a标签实现
				let link = document.createElement("a");
				link.href = uri;
				//对下载的文件命名
				link.download = `${fileName || '表格数据'}.csv`;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
			clickDown () {
				let title = "";
				let content = "";
				let arr = [];
				for (const i in this.template_content_args_list) {
					title += this.template_content_args_list[i].label + ","
					for (const j in this.template_content_list) {
						if (!arr[j]){
							arr[j] = "";
						}
						let value = this.template_content_list[j][this.template_content_args_list[i].id];
						if (value === undefined){
							value = "";
						}
						arr[j] += value+",";
					}
				}
				for (const i in arr) {
					content += arr[i].substring(0,arr[i].length-1)+'\n';
				}

				title = title.substring(0,title.length-1)+'\n';
				console.log(this.room_content_type,this.room_tab-2)

				this.downLoadExcel(title+content,this.room_content_type[this.room_tab-2].name+"_"+new Date().getTime())
			},


			get_content_list: function () {
				var that = this;
				that.show_skeleton = true;

				var params = {};
				params.roomId = that.id;
				params.page = that.page;

				params.pageSize = 10;

				params.menuId = that.menuId;

				if (that.page == 1){
					that.template_content_args_list = [];
				}
                if (that.menuInfo.isKwFormat) {
                  this.newApi.getRoomMenuContent(params).then(ret => {
                    for (let i = 0; i < ret.data.length; i++) {
                      if (ret.data[i].menuType === 2) {
                        if (ret.data[i].content.images.length > 0) {
                          ret.data[i].menuType = 3
                        }
                        if (ret.data[i].content.videos.length > 0) {
                          ret.data[i].menuType = 4
                        }
                        if (ret.data[i].content.enclosure.length > 0) {
                          ret.data[i].menuType = 5
                        }
                      }

                      that.content_list.push(ret.data[i]);
                    }
                    if (ret.data.length < 10) {
                      that.more = 0;
                    }
                    that.page++;
                    that.show_skeleton = false;
                  })
                } else {
                  this.newApi.getRoomMenuContent(params).then(ret => {
                    if (that.classType && that.classType == 3 && ret.data.length > 0 ){
                      //处理模版文章 表格模式  字段
                      var argList = ret.data[0].data.list;
                      for (let i = 0; i < argList.length; i++) {
                        //表格视图
                        if (argList[i].layoutArray && argList[i].layoutArray.length > 0){
                          var layoutArray = argList[i].layoutArray;
                          for (let j = 0; j < layoutArray.length; j++) {
                            var tr = layoutArray[j];
                            for (let k = 0; k < tr.length; k++) {
                              var td = tr[k];
                              for (let l = 0; l < td.columns.length; l++) {
                                var arg = td.columns[l];
                                if (["divider","link","text","simpleImage","space"].indexOf(arg.compType) == -1){
                                  that.template_content_args_list.push(arg);
                                }
                              }
                            }
                          }
                        }else

                            //栅格视图
                        if (argList[i].columns && argList[i].columns.length > 0){
                          for (let j = 0; j < argList[i].columns.length; j++) {
                            var column = argList[i].columns[j];
                            for (let k = 0; k < column.list.length; k++) {
                              var arg = column.list[k];
                              if (["divider","link","text","simpleImage","space"].indexOf(arg.compType) == -1){
                                that.template_content_args_list.push(arg);
                              }
                            }
                          }
                        }

                        //其他的
                        else{
                          if (["divider","link","text","simpleImage","space"].indexOf(argList[i].compType) == -1){
                            that.template_content_args_list.push(argList[i]);
                          }
                        }
                      }

                      console.log("template_args_content_list");
                      console.log(argList);
                    }

                    for (let i = 0; i < ret.data.length; i++) {
                      if (ret.data[i].menuType === 2) {
                        if (ret.data[i].content.images.length > 0) {
                          ret.data[i].menuType = 3
                        }
                        if (ret.data[i].content.videos.length > 0) {
                          ret.data[i].menuType = 4
                        }
                        if (ret.data[i].content.enclosure.length > 0) {
                          ret.data[i].menuType = 5
                        }
                      }
                      that.content_list.push(ret.data[i]);
                      if (that.classType && that.classType == 3){
                        //处理模版文章 表格模式 内容
                        const content = JSON.parse(JSON.stringify(ret.data[i].content));
                        for (const item in content) {
                          if (typeof content[item] == "string"){
                            content[item] = that.toText(content[item],20);
                          }
                        }
                        that.template_content_list.push(content);
                      }
                    }
                    if (ret.data.length < 10) {
                      that.more = 0;
                    }
                    that.page++;
                    that.show_skeleton = false;
                  })
                }

			},

			get_room_info: function () {
				var that = this;
				this.newApi.getRoomInfo({
					roomId: that.id
				}).then(ret => {
          ret.data.roomType = 1
					that.room = ret.data;
          that.roomUrl = that.typeList.find(e => e.value == that.room.roomType).url
					if (that.room.myLv === 0) {
						that.menuId = '0';
						that.room_tab = 1;
            if (that.is_login) {
              this.get_content_list();
            }
					} else {
						that.menuId = '-1';
						that.room_tab = 0;
            if (that.is_login) {
              this.get_content_list();
            }
					}
				})
			},

			room_user_add() {
				var that = this;
        if (!this.utils.toAuth(1)) {
          return false;
        }
					this.newApi.applyToRoomUser({roomId: this.room.id}).then(ret => {
						if (ret.isSuccess == 1) {
							that.utils.sus(ret.data);
							that.get_room_info();
						}
					})
			},

			setVideo(userList) {
				this.videoCall(userList, this.$route.params.id)
			},

			room_set(command) {
				if (command == "a") {
					this.upShow = true;
					this.eventType = command;
					this.eventName = '活动报名'
				} else if (command == "b") {
					this.upShow = true;
					this.eventType = command;
					this.eventName = '视频直播'
				} else if (command == "c") {
					this.upShow = true;
					this.eventType = command;
					this.eventName = '审批流文档'
				} else if (command == "e") {
					this.CalendarShow=true
				} else if (command == "f") {
					this.upShow = true;
					this.eventType = command;
					this.eventName = '打卡事件'
				} else if (command == "j") {
					this.upShow = true;
					this.eventType = command;
					this.eventName = '付费文档'
				} else if (command == "z") {
					this.collection_room(this.room);
				}else if (command == "v") {
                  if (this.call.status === 0) {
                    this.upShow = true;
                    this.eventType = command;
                    this.eventName = '视频会议'
                  } else {
                    this.utils.msg('视频未结束，无法再次发起视频')
                  }


					// let userList = [];
          //
					// for (let i = 0; i < this.room_user_list.adminList.length; i++) {
					// 	if (this.user.uuid !== this.room_user_list.adminList[i].uuid){
					// 		userList.push(this.room_user_list.adminList[i].uuid);
					// 	}
					// }
					// for (let i = 0; i < this.room_user_list.userList.length; i++) {
					// 	if (this.user.uuid !== this.room_user_list.userList[i].uuid){
					// 		userList.push(this.room_user_list.userList[i].uuid);
					// 	}
					// }
          //
					// this.videoCall(userList);
				}else if(command == "y"){
					if (this.living){
						return;
					}
					this.role = "anchor";
					this.living = true;
				}else if(command == "r"){
                    this.wxshareDialong = true;
                }
				// else if (command == "k") {
				//     this.upShow = true;
				//     this.eventType = command;
				//     this.eventName = '支付事件'
				// }
			},
			onCloseLive(){
				this.living = false;
				this.role = "";
			},
			onCreateLive(){

			},
			share() {
				this.$EventBus.$emit("share_room", {room_id: this.id});
			},

			get_room_user_list() {
				var that = this;
				this.newApi.getRoomUserList({
					roomId: this.id
				}).then(ret => {
					that.room_user_list = ret.data;
				})
			},

			collection_user: function (item) {
				this.collection(2, item);
			},

      toShowCommand() {
        if(this.user.realNameStatus === 0 || this.user.realNameStatus === 1){
          this.utils.realNameAuthJump(this.user.uuid,this.user.realNameStatus,this.user.userType)
        } else {
          this.showCommand = true
        }
      },

			// 关注room
			collectionRoom(id) {
        if (!this.utils.toAuth(1)) {
          return false;
        }
          this.newApi.collectRoom({roomId: id}).then(res =>{
            this.utils.sus(res.data);
            this.content_list = [];
            this.template_content_list = [];
            this.room_tab = 0;
            this.page = 1;
            this.more = 1;
            this.get_content_list();
            this.get_room_info();
            this.getMenuList();
            this.get_room_user_list();
          })
        }
			},
	}
</script>

<style scoped>
  .tag-type {
    position:absolute;
    top: 0;
    left: 0;
    border: 16px solid #FFFFFF;
    border-bottom-color:transparent;
    border-right-color: transparent;
  }
  .tag-type-bottom {
    position:absolute;
    bottom: 0;
    right: 0;
    border: 16px solid #FFFFFF;
    border-top-color:transparent;
    border-left-color: transparent;
  }
	.bottom{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px;
	}
	.bottom .btns{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.bottom .btns .btn{
		width: 72px;
		display: flex;
		position: relative;
		margin-right: 22px;
		justify-content: center;
		align-items: center;
		height: 18px;
		font-size: 16px;
		color: #6B6B6B;
		cursor: pointer;
	}
	.room_tab {
		margin-left: 15px;
		line-height: 50px;
		font-size: 15px;
		width: auto;
		text-align: center;
	}

	.room_type_bottom {
		width: auto;
		border-bottom: 4px solid #5A6FE6;
		font-weight: bold;
	}

	/deep/ .el-input__inner {
		height: 35px;
		line-height: 35px;
	}

	.tags {
		width: 72px;
		height: 25px;
		line-height: 25px;
		background: #FFFFFF;
		border-radius: 3px 3px 3px 3px;
		opacity: 1;
		border: 1px solid #BCD2FF;
		font-size: 12px;
		color: #2970FF;
		text-align: center;
		margin-right: 10px;
		margin-top: 10px;
		padding: 0 5px;
		box-sizing: border-box;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.tag {
		width: 72px;
		height: 25px;
		line-height: 25px;
		background: #FFFFFF;
		border-radius: 3px 3px 3px 3px;
		opacity: 1;
		border: 1px solid #FFA500;
		font-size: 12px;
		color: #FFA500;
		text-align: center;
		margin-right: 10px;
		margin-top: 10px;
		padding: 0 5px;
		box-sizing: border-box;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

  .new-tag {
    flex:1;background-color: #ffffff;margin-bottom:5px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    text-align: center;height: 46px;line-height: 42px
  }

	.save-btn {
		width: 68px;
		height: 38px;
		color: #fff;
		background-color: #1C7EFF;
		border-radius: 5px;
	}

	.cancel-btn {
		width: 68px;
		height: 38px;
		margin-right: 18px;
		background-color: #E4E6EB;
		border-radius: 5px;
	}

	.drop-img {
		width: 16px;
		height: 16px;
		margin-right: 6px;
		vertical-align: middle
	}

	.event-form .top {
		text-align: center;
		position: relative;
		font-size: 20px;
		font-weight: 600;
		color: #444444;
		border: 1px solid #CECFD0;
		height: 60px;
		box-sizing: border-box;
		line-height: 60px;
	}

	.event-form .contentType{
		font-size: 18px;
		color: #031F88;
		position: absolute;
		left: 20px;
		font-weight: 600;
		margin-right: 10px;
	}

	.event-form .room_head{
		width: 40px;
		height: 40px;
		background: #FFFFFF;
		border: 1px solid #707070;
		box-sizing: border-box;
		border-radius: 50%;
	}

	.event-form .hb{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.event-form .room_name{
		width: calc( 100% - 45px);
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2; /* 这里是超出几行省略 */
		overflow: hidden;
		color: #555555;
		font-weight: 600;
		font-size: 14px;
	}

  /deep/ .event-form .el-dialog__header {
    padding: 0;
  }

  /deep/ .event-form .el-dialog__body {
    padding: 0;
  }

  /deep/ .calendar-form .el-dialog__header {
    padding: 10px 10px 0 10px;
  }

  /deep/ .calendar-form .el-dialog {
    margin-bottom: 0;
  }

  /deep/ .calendar-form .el-dialog__body {
    padding: 0 10px 10px 10px;
  }

	/deep/ .event-form .el-dialog__body {
		padding: 0;
	}
	/deep/ .event-form .el-dialog__header{
		padding: 0;
	}

  /deep/ .input-with-select .el-input-group__append {
    background-color: #2970FF;
    border: 1px solid #2970FF;
  }

  .el-input-group__append{
    background-color: #2970FF;
    color: #ffffff;
  }
  .el-input__inner{
    height: 45px;
  }


	.contentType{
		color: #031F88;
		height: 16px;
		font-weight: 600;
		margin-right: 10px;
	}

  .inner-container::-webkit-scrollbar {
    display: none;
  }

	.table-layout{
		background-color: #ffffff;
		border-collapse: collapse;

		padding: 8px !important;
		text-align: left;
		margin: 0 auto;
		width: 100%;
		table-layout: fixed;
	}

	.table-layout td{
		padding: 6px;
		word-break: break-word;
		border: 1px solid #d2d2d2;
		height: 20px;
	}

	.table-layout tr{
		border-bottom: 1px solid #d2d2d2;
		border-top: 1px solid #d2d2d2;
	}
    /deep/ .el-dialog__header {
        padding: 30px 20px 10px;
    }

    /deep/.el-dialog__body {
        padding: 0px 0px;
    }

  .notice-btn {
    font-weight: 600;
  }

</style>
